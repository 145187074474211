export default {
  // 去除前后空格
  trim (str) {
    return str.replace(/^\s*(.*?)\s*$/g, '$1')
  },
  // 数组去重
  getFilterArray (array) {
    const set = new Set(array)
    return [...set]
  },
  randomsort () {
    return Math.random() > 0.5 ? -1 : 1 // 用Math.random()函数生成0~1之间的随机数与0.5比较，返回-1或1
  },
  formatDate (date, fmts = 'yyyy-MM-dd') {
    let fmt = fmts
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  },
  formatDatetime (date, fmts) {
    let fmt = fmts
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  },
  getAgeByBirthday (strBirthday) {
    /**
     *参数strBirthday已经是正确格式的2017-12-12这样的日期字符串
     */
    let returnAge = null
    const strBirthdayArr = strBirthday.split('-')
    const birthYear = strBirthdayArr[0]
    const birthMonth = strBirthdayArr[1]
    const birthDay = strBirthdayArr[2]

    const d = new Date()
    const nowYear = d.getFullYear()
    const nowMonth = d.getMonth() + 1
    const nowDay = d.getDate()

    if (nowYear === birthYear) {
      returnAge = 0
    } else {
      const ageDiff = nowYear - birthYear
      if (ageDiff > 0) {
        if (nowMonth === birthMonth) {
          const dayDiff = nowDay - birthDay
          if (dayDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        } else {
          const monthDiff = nowMonth - birthMonth
          if (monthDiff < 0) {
            returnAge = ageDiff - 1
          } else {
            returnAge = ageDiff
          }
        }
      } else {
        returnAge = -1
      }
    }
    return returnAge
  }
}
